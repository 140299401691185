import React, { useEffect, useState } from "react";
import { ReactComponent as Comingsoon } from "../../assets/svgs/coming_soon.svg";
import { useNavigate } from "react-router";
import { Box, Button, Typography } from "@mui/material";

function ComingSoon() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    // Set target date to January 1, 2026 at 00:00:00
    const targetDate = new Date("2026-01-01T00:00:00");

    const updateTimer = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (2400 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    // Update the timer every second
    updateTimer(); // Call once immediately to avoid 1-second delay
    const timerInterval = setInterval(updateTimer, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timerInterval);
  }, []);
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        gap: 2,
      }}
    >
      <Comingsoon style={{ width: "100%" }} />
      {Boolean(
        timeLeft?.days ||
          timeLeft?.hours ||
          timeLeft?.minutes ||
          timeLeft?.seconds
      ) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {[
            { label: "Days", value: timeLeft.days },
            { label: "Hrs", value: timeLeft.hours },
            { label: "Mins", value: timeLeft.minutes },
            { label: "Secs", value: timeLeft.seconds },
          ].map((time, index) => (
            <Box
              key={index}
              sx={{
                background:
                  "linear-gradient(to bottom, #FFC700 50%, #FFA100 50%)", // top & bottom colors
                padding: 2,
                borderRadius: 2,
                minWidth: 40,
                textAlign: "center",
                width: "65px",
                height: "70px",
                mt: -12,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Optional: subtle shadow
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  lineHeight: 1.2,
                }}
              >
                {time.value}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                }}
              >
                {time.label}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      <Button
        variant="contained"
        sx={{
          bgcolor: "#E5AFFB", // Custom red color
          color: "black",
          borderRadius: "50px",
          px: 3,
          fontSize: "16px",
          textTransform: "none",
          height: "50px",
          "&:hover": {
            bgcolor: "#E5AFFB",
          },
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        Go to Homepage
      </Button>
    </Box>
  );
}

export default ComingSoon;
