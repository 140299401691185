// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"Urbanist", sans-serif',
    // You can specify variants for Typography
    h1: { fontFamily: '"DM Sans", sans-serif' },
    h2: { fontFamily: '"DM Sans", sans-serif' },
    h3: { fontFamily: '"DM Sans", sans-serif' },
    body1: { fontFamily: '"DM Sans", sans-serif' },
    body2: { fontFamily: '"DM Sans", sans-serif' },
    button: { fontFamily: '"DM Sans", sans-serif' },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
          textTransform: "none", // Optional: Removes uppercase for buttons
          boxShadow: "none !important",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
          textTransform: "none", // Removes uppercase for tabs
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          fontFamily: '"Urbanist", sans-serif',
        },
      },
    },
  },
});

export default theme;
