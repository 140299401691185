import React, { useState } from "react";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import NearBy from "../../assets/near_by.png";

const steps = [
  {
    title: "Near by you",
    description:
      "Tell us where you are—we’ll show you top nearby boutiques to choose from.",
    bg: "#FA803D",
    label: "STEP 1",
    image: NearBy,
    color: "#ffffff",
    des_color: "#ffffff",
    id: 1,
  },
  {
    title: "Your way / Recommended",
    description:
      "Pick a design from our boutique collection or send us your favorite style — we’ll stitch it just for you!",
    bg: "#E6CEF0",
    label: "STEP 2",
    image: NearBy,
    color: "#292929",
    des_color: "#292929",
    id: 2,
  },
  {
    title: "Measurement",
    description:
      "We measure, collect, and deliver your fabric to your preferred boutique — right from your doorstep.",
    bg: "#FFC751",
    label: "STEP 3",
    image: NearBy,
    color: "#5C5F5F",
    des_color: "#5C5F5F",
    id: 3,
  },
  {
    title: "On the way",
    description: "Stitched and ready? We’ll bring it straight to your door!",
    bg: "#F39765",
    label: "STEP 4",
    image: NearBy,
    color: "#5C5F5F",
    des_color: "#5C5F5F",
    id: 4,
  },
  {
    title: "Delivery",
    description: "Clothes stitched and delivered on time—Just as promised.",
    bg: "#E5AFFB",
    label: "STEP 5",
    image: NearBy,
    color: "#000000",
    des_color: "#000000",
    id: 5,
  },
];

const StepCard = ({ step, isMain, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: step.bg,
        p: 2,
        borderRadius: 3,
        display: "flex",
        flexDirection: isMain ? (isMobile ? "column" : "row") : "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: isMain ? (isMobile ? 300 : 350) : isMobile ? 80 : 250,
        width: isMobile ? "85%" : isMain ? "70%" : "5%",
        transition: "all 0.3s ease-in-out",
        cursor: isMain ? "default" : "pointer",
        m: isMobile && "auto",
        mb: isMobile ? 2 : 0,
      }}
    >
      {isMain ? (
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          spacing={isMobile ? 2 : 4}
        >
          <Box
            component="img"
            src={step.image}
            alt={step.title}
            sx={{
              width: isMobile ? "100%" : "40%",
              height: "auto",
              borderRadius: 2,
            }}
          />
          <Box textAlign={isMobile ? "center" : "left"}>
            <Typography variant="caption" sx={{ color: step.color }}>
              {step.label}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: step.color }}
              fontSize={isMobile ? 16 : 45}
              mt={1}
            >
              {step.title}
            </Typography>
            <Typography
              mt={1}
              fontSize={isMobile ? 16 : 35}
              fontWeight={400}
              sx={{ color: step.des_color }}
            >
              {step.description}
            </Typography>
          </Box>
        </Stack>
      ) : (
        <Stack
          direction="column"
          justifyContent={isMobile ? "center" : "flex-end"}
          alignItems="center"
          height="100%"
          gap={isMobile ? 2 : 10}
        >
          <Typography
            sx={{
              writingMode: !isMobile && "vertical-rl",
              transform: !isMobile && "rotate(180deg)",
              textAlign: "center",
              color: step.color,
              fontSize: isMobile ? 16 : 25,
            }}
            mt={1}
          >
            {step.title}
          </Typography>
          <Typography
            fontWeight="bold"
            sx={{
              writingMode: !isMobile && "vertical-rl",
              transform: !isMobile && "rotate(180deg)",
              textAlign: "center",
              color: step.color,
            }}
          >
            {step.label}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default function StitchInStepsFlow({ id }) {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box id={id} className="scroll-target">
      <Typography
        textAlign="center"
        variant={isMobile ? "h5" : "h4"}
        mb={isMobile ? 2 : 4}
        fontWeight={600}
      >
        How StitchIn Works?
      </Typography>

      {isMobile ? (
        <Box display="flex" flexDirection="column" width="100%">
          {/* Previous Steps */}
          {steps.slice(0, activeStep).map((step, index) => (
            <StepCard
              key={step.id}
              step={step}
              isMain={false}
              onClick={() => setActiveStep(index)}
            />
          ))}

          {/* Active Step */}
          <StepCard step={steps[activeStep]} isMain={true} onClick={() => {}} />

          {/* Remaining Steps */}
          {steps.slice(activeStep + 1).map((step, index) => (
            <StepCard
              key={step.id}
              step={step}
              isMain={false}
              onClick={() => setActiveStep(index + activeStep + 1)}
            />
          ))}
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="flex-start"
          overflow="auto"
          sx={{ gap: 2, width: "100%", flexWrap: "nowrap" }}
        >
          {/* Previous steps on left */}
          {steps.slice(0, activeStep).map((step, index) => (
            <StepCard
              key={step.id}
              step={step}
              isMain={false}
              onClick={() => setActiveStep(index)}
            />
          ))}

          {/* Main step */}
          <StepCard step={steps[activeStep]} isMain={true} onClick={() => {}} />

          {/* Next steps on right */}
          {steps.slice(activeStep + 1).map((step, index) => (
            <StepCard
              key={step.id}
              step={step}
              isMain={false}
              onClick={() => setActiveStep(index + activeStep + 1)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
