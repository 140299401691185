import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Button,
  Collapse,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const faqData = [
  {
    id: 1,
    question: "What is StitchIn.in?",
    answer:
      "StitchIn.in is an online platform offering doorstep tailoring, alterations, and laundry services with same-day delivery through a quick commerce model.",
  },
  {
    id: 2,
    question: "How does the tailoring service work?",
    answer:
      "Customers log in, select a design or upload their own, book a measurement slot, and our expert visits to take measurements. Stitched clothes are delivered within 3 hours from the nearest stitching hub.",
  },
  //   {
  //     id: 3,
  //     question: "I don’t have fabric. Can I still use the service?",
  //     answer:
  //       "Yes, you can select from our collection of branded fabrics or choose modern and vintage ready-to-stitch outfits directly on the platform.",
  //   },
  {
    id: 4,
    question: "What is the delivery time for stitched clothes?",
    answer:
      "We deliver stitched garments within 24 hours after pickup and measurement, using our local stitching hubs located every 5 km.",
  },
  {
    id: 5,
    question: "What types of clothes can I get stitched?",
    answer:
      "We offer stitching for ethnic wear, formal wear, kidswear, wedding dresses, suits, kurtas, and more—for both men and women.",
  },
  {
    id: 6,
    question: "What is the laundry service and how does it work?",
    answer:
      "You can schedule a laundry or dry-cleaning pickup via the app. Clothes are washed, ironed, and delivered back to you the same day.",
  },
  {
    id: 7,
    question: "How do I pay for the services?",
    answer: "We support payments via UPI and pay on deilvery.",
  },
  {
    id: 8,
    question: "Are the tailors and laundry staff trained?",
    answer:
      "Yes, all our tailors and staff are professionally trained and vetted to ensure high-quality service and customer satisfaction.",
  },
  //   {
  //     id: 9,
  //     question: "Is customer support available?",
  //     answer:
  //       "Yes, our customer support is available 24x7 via in-app chat, email (support@stitchin.com), or phone.",
  //   },
  {
    id: 10,
    question: "Where is StitchIn currently available?",
    answer:
      "We are launching in metro cities first, with plans to expand to Tier 2 cities soon. Call us for the services.",
  },
];

export default function FAQSection() {
  const [expandedId, setExpandedId] = useState(0);

  const toggleExpand = (id) => {
    setExpandedId((prev) => (prev === id ? null : id));
  };

  return (
    <Box sx={{ py: 6, px: { xs: 2, md: 6 }, backgroundColor: "#FCFCFD" }}>
      <Typography variant="h4" align="center" sx={{ fontWeight: 600, mb: 6 }}>
        Frequently Asked Questions (FAQ)
      </Typography>

      <Grid container spacing={4}>
        {/* Left - FAQ Accordion */}
        <Grid size={{ xs: 12 }}>
          {faqData.map((faq, index) => (
            <Card
              key={faq.id}
              sx={{
                mb: 2,
                p: 3,
                backgroundColor: "#ECE2F0",
                borderRadius: 3,
                boxShadow: "none",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => toggleExpand(faq.id)}
                className="cursor"
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        fontSize: 24,
                        fontWeight: 700,
                        color: "#A1A0B5",
                        mr: 2,
                        width: 30,
                      }}
                    >
                      {`0${index + 1}`}
                    </Box>
                    {faq.question}
                  </Typography>
                </Box>
                <IconButton>
                  {expandedId === faq.id ? <CloseIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Collapse in={expandedId === faq.id} timeout="auto" unmountOnExit>
                <Typography sx={{ mt: 2, color: "#4A4A4A" }}>
                  {faq.answer}
                </Typography>
              </Collapse>
            </Card>
          ))}
        </Grid>

        {/* Right - Contact Card */}
        {/* <Grid size={{ xs: 12, md: 4 }}>
          <Card
            sx={{
              height: { xs: "70%", sm: "70%", md: "30%" },
              borderRadius: 3,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              border: "1px solid #E0E0E0",
              boxShadow: "none",
            }}
          >
            <MailOutlineIcon sx={{ fontSize: 40, mb: 2 }} />
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
              Do you have more questions?
            </Typography>
            <Typography variant="body2" sx={{ color: "#666", mb: 3 }}>
              If you have any questions or need assistance, feel free to reach
              out to us. We're here to help!
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FD84A3",
                color: "#fff",
                px: 4,
                borderRadius: 999,
                textTransform: "none",
                fontWeight: 600,
                boxShadow: "none",
                outline: "none",
                "&:hover": {
                  boxShadow: "none",
                  outline: "none",
                },
              }}
            >
              Shoot a Direct Mail
            </Button>
          </Card>
        </Grid> */}
      </Grid>
    </Box>
  );
}
