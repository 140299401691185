import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const socialIcons = [
  {
    id: 1,
    href: "https://www.stitchin.in",
    icon: <InstagramIcon />,
  },
  { id: 2, href: "https://www.stitchin.in", icon: <FacebookIcon /> },
  {
    id: 3,
    href: "https://www.stitchin.in",
    icon: <YouTubeIcon />,
  },
  {
    id: 4,
    href: "https://www.stitchin.in",
    icon: <LinkedInIcon />,
  },
];
