import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CurvedBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.only("lg"));
  const isMidTab = useMediaQuery(theme.breakpoints.only("md"));
  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
        position: "absolute", // Absolute positioning
        top: isMobile ? 350 : isTab ? 570 : 320, // Moved further down
        zIndex: -1, // Behind all content
      }}
    >
      <svg width="100%" height="300" viewBox="0 0 2400 300">
        <path
          id="bannerPath"
          d={
            "M -100 150 C 300 0, 700 300, 1200 150 C 1700 0, 2100 300, 2600 150"
          }
          fill="none"
          stroke="#FA803D"
          strokeWidth={isMobile ? "200" : isMidTab ? "150" : "70"}
        />

        <text
          fill="#ffffff"
          fontSize={isMobile ? "60" : isMidTab ? "35" : "28"}
          fontWeight="bold"
        >
          <textPath href="#bannerPath" startOffset="-100%">
            <animate
              attributeName="startOffset"
              from="-100%"
              to="0%"
              dur="15s"
              repeatCount="indefinite"
            />
            {Array(10).fill("StitchIn ▲ Where fashion is fast ").join("")}
          </textPath>
        </text>
      </svg>
    </div>
  );
};

export default CurvedBanner;
