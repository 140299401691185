import React, { useContext, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Footer from "../../components/Home/Footer";
function FooterContainer() {
  const subscribeSchema = yup.object({
    email: yup
      .string()
      .transform((value) => (typeof value === "string" ? value.trim() : value)) // Trim whitespace
      .email("Enter a valid email")
      .required("Email is required")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(subscribeSchema),
  });

  // reset({
  //   email: "",
  // });
  const handleSubscribe = async (data) => {};

  return (
    <Footer
      register={register}
      errors={errors}
      handleSubmit={handleSubmit(handleSubscribe)}
    />
  );
}

export default FooterContainer;
