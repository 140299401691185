import React, { useState } from "react";
import {
  Box,
  IconButton,
  Container,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CompanyLogo } from "../../assets/svgs/StitchIn.svg";
import { ReactComponent as MobileCompanyLogo } from "../../assets/svgs/StitchIn_mobile.svg";
import { ReactComponent as Icon1 } from "../../assets/svgs/icon1.svg";
import { ReactComponent as Icon2 } from "../../assets/svgs/icon2.svg";
import { ReactComponent as Icon3 } from "../../assets/svgs/icon3.svg";
import { ReactComponent as Icon4 } from "../../assets/svgs/icon4.svg";
function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const goToHome = () => {
    navigate("/");
    window.location.reload();
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const pages = [
    {
      id: 1,
      name: "Services",
      icon: <></>,
      path: "/",
      hash: "services",
    },
    {
      id: 2,
      name: "How it works?",
      icon: <></>,
      path: "/",
      hash: "how-it-works",
    },
    {
      id: 2,
      name: "",
      icon: (
        <CompanyLogo
          className="cursor"
          style={{
            marginLeft: 0, // Ensures no margin issues
            display: "block", // Always displays the logo
          }}
          onClick={goToHome}
        />
      ),
      path: "/",
    },
    {
      id: 3,
      name: "Why Us?",
      icon: <></>,
      path: "/",
      hash: "why-us",
    },
    {
      id: 4,
      name: "FAQs",
      icon: <></>,
      path: "/",
      hash: "faqs",
    },
  ];

  const mobilePages = [
    {
      id: 1,
      name: "Services",
      icon: <Icon1 />,
      path: "/",
      hash: "services",
    },
    {
      id: 2,
      name: "How it works?",
      icon: <Icon2 />,
      path: "/",
      hash: "how-it-works",
    },
    {
      id: 3,
      name: "Why Us?",
      icon: <Icon3 />,
      path: "/",
      hash: "why-us",
    },
    {
      id: 4,
      name: "FAQs",
      icon: <Icon4 />,
      path: "/",
      hash: "faqs",
    },
  ];

  const handlePageClick = (event, page) => {
    setDrawerOpen(false);
    if (page.path === "/") {
      if (location.pathname === "/") {
        // Already on homepage — scroll to section
        document
          .getElementById(page.hash)
          ?.scrollIntoView({ behavior: "smooth" });
      } else {
        // Navigate to homepage and scroll after a slight delay
        navigate("/", { replace: false });
        setTimeout(() => {
          const section = document.getElementById(page.hash);
          section?.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    } else {
      navigate(page.path);
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        className="bg-white"
        sx={{
          color: "black",
          height: "80px !important",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          boxShadow: "none",
        }}
      >
        <Container maxWidth="100%">
          <Box
            sx={{
              display: "flex",
              alignItems: "center", // Align items vertically in the center
              gap: 1, // Add slight spacing between MenuIcon and CompanyLogo
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", sm: "none" },
                gap: 5,
                alignItems: "center",
              }}
            >
              <IconButton
                size="large"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon sx={{ color: "#FA803D", fontSize: "35px" }} />
              </IconButton>
              <MobileCompanyLogo
                className="cursor"
                style={{
                  marginLeft: 15, // Ensures no margin issues
                  display: "block", // Always displays the logo
                }}
                onClick={goToHome}
              />
            </Box>
          </Box>

          {/* Pages on Right */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center", // Align items vertically
              ml: 1,
              width: "50%",
              m: "auto",
            }}
          >
            {/* Pages buttons, hidden on small screens */}
            <Box
              sx={{
                display: { xs: "none", sm: "flex" }, // Visible only on large screens
                justifyContent: "space-between",
                flexGrow: 1, // Allow space for proper alignment
                flexShrink: 0, //
              }}
            >
              {pages.map((page) => (
                <Button
                  startIcon={page.icon}
                  key={page.id}
                  className="fw-600 fs-20"
                  sx={{
                    mx: 1,
                    color: "#515151", // Default color for text and icon
                    textTransform: "capitalize",
                    textDecoration: "none",
                    textWrap: "no-wrap",
                    "&:hover": {
                      background: "none !important", // Prevent background change
                      boxShadow: "none", // Prevent box-shadow on hover
                      color: "#FA803D", // Change text and icon color on hover
                    },
                  }}
                  onClick={(event) => {
                    console.log(page, "page");
                    handlePageClick(event, page); // This function should be triggered
                  }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
          </Box>
        </Container>
      </AppBar>

      {/* Drawer with Settings */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: 2,
            }}
          >
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon sx={{ color: "#FA803D" }} />
            </IconButton>
          </Box>
          <List>
            {mobilePages.map((page) => (
              <ListItem key={page.id} component="div" disablePadding>
                <ListItemButton
                  onClick={(event) => handlePageClick(event, page)}
                >
                  <ListItemIcon sx={{ color: "#FA803D" }}>
                    {page.icon}
                  </ListItemIcon>
                  <ListItemText primary={page.name} sx={{ color: "#FA803D" }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default Header;
