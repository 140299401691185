/* eslint-disable*/

import React, { useRef } from "react";
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  Outlet,
  RouterProvider,
  Navigate,
  useLocation,
} from "react-router-dom";
import NotFound from "../components/404NotFound/index";
import Header from "../components/Header";
import Home from "../components/Home";
import { Box } from "@mui/material";
import FooterContainer from "../containers/Footer";
import ComingSoon from "../components/Home/Coming";

// const PrivateRoute = ({ children }) => {
//   const speechSynthesisRef = useRef(window.speechSynthesis);
//   speechSynthesisRef.current.cancel();
//   const access_token = sessionStorage.getItem("access_token");
//   return <>{access_token ? children : <Navigate to="/login" />}</>;
// };

const BasicLayout = () => {
  const location = useLocation();
  return (
    // <ErrorBoundaryFn>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensures the container covers the full viewport height
      }}
    >
      <Header />
      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
      {!["/coming_soon"].includes(location.pathname) && <FooterContainer />}
    </Box>
    // </ErrorBoundaryFn>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route key="/" path="/" element={<BasicLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="/coming_soon" element={<ComingSoon />} />
      <Route key="*" path="*" element={<NotFound />} />
    </Route>
  )
);

export default (
  <div className="container-fluid p-0">
    <RouterProvider router={router} />
  </div>
);
