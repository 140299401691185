/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { ReactComponent as BackToTopSvg } from "../../assets/svgs/top.svg";

import styles from "./index.module.scss";

function BackToTop() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleBackToTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This will make the scroll behavior smooth
    });
  };

  return (
    <Box className={styles.movableImage} onClick={handleBackToTopClick}>
      {showButton && <BackToTopSvg />}
    </Box>
  );
}

export default BackToTop;
