import React from "react";
import { Container, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Solutions } from "../../assets/svgs/solution.svg";
import { ReactComponent as Solutions2 } from "../../assets/svgs/mobile_solution.svg";

const FashionBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container maxWidth="xl" sx={{ m: "auto" }}>
      {isMobile ? (
        <Solutions2 style={{ width: "100%" }} />
      ) : (
        <Solutions style={{ width: isTab ? "60%" : "100%" }} />
      )}
    </Container>
  );
};

export default FashionBanner;
