import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ReactComponent as Services1 } from "../../assets/svgs/Services 6.svg";
import { ReactComponent as Services2 } from "../../assets/svgs/Services 5.svg";
import { ReactComponent as Services3 } from "../../assets/svgs/Services 3.svg";
import { ReactComponent as Services4 } from "../../assets/svgs/Services 2.svg";

const services = [
  { images: <Services4 style={{ width: "95%", height: "100%" }} />, id: 1 },
  { images: <Services2 style={{ width: "95%", height: "100%" }} />, id: 2 },
  { images: <Services3 style={{ width: "95%", height: "100%" }} />, id: 3 },
  { images: <Services1 style={{ width: "95%", height: "100%" }} />, id: 4 },
];

function Services({ id }) {
  return (
    <>
      <Box
        id={id}
        className="scroll-target"
        sx={{ backgroundColor: "#E5AFFB", py: 4, px: 2 }}
      >
        <Typography
          variant="h3"
          sx={{ textAlign: "left", fontWeight: "500px", color: "#333", mb: 4 }}
        >
          Our Services
        </Typography>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          {services.map((service, index) => (
            <Grid
              size={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 12 }}
              key={service.id}
            >
              {service.images}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default Services;
