/* eslint-disable*/

import React from "react";
import { useNavigate } from "react-router";
import { Stack, Button } from "@mui/material";
import styles from "./index.module.scss";
const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        margin: "90px 20px 20px 20px",
      }}
    >
      <iframe
        src="https://lottie.host/embed/da292529-15f7-4185-8cff-56d3f1a2eb06/8tiJi8Fdek.lottie"
        style={{
          border: "none",
          outline: "none",
          width: "100%",
          height: "300px",
        }}
        title="Page Not Found"
      ></iframe>
      <br />
      <br />
      <p className="fs-36">“Fashion is fast”</p>
      <br />
      <Button
        className={styles.homeBtn}
        variant="contained"
        onClick={() => navigate("/")}
      >
        Go to Home
      </Button>
    </Stack>
  );
};

export default NotFound;
